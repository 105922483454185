.box{
  background: #FFFFFF;
  border-radius: $radius;

  @include lgm{

  }
}

//BOX CALENDAR
.box_calendar{ 

  @include lg{
    display: flex;
  }

  &_left{
    flex: 0 0 260px;
    border-right: 1px solid $color13;

    @include lgm{
      border: 0;
      border-bottom: 1px solid $color13;      
    }
  }

  &_right{
    flex: 1 1 auto;
  }
}

//FIRST SCREEN
.box_first{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

//POPUP
.popup{
  @include abs-100;  
  position: fixed;
  background-color: rgba(#fff,.95);
  z-index: $zindex_header + 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s;

  .show_popup &{
    opacity: 1;
    pointer-events: all;
  }

  &_inner{
    display: flex;
    align-items: flex-start;    
    overflow-x: hidden;    
    height: 100%;
    opacity: 0;
    transform: scale(.9);
    transition: all 0s .5s;
  }

  .show_popup &_inner{
    opacity: 1;
    transform: none;
    transition: all .5s .3s;
  }

  &_content{
    margin: auto;
  }

  &_close{
    @include button-reset;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    padding: 10px;
    transition: color .25s;

    &:hover{
      color: $color6;
    }
  }
}

//MODAL
.modal{
  @include abs-100;  
  display: flex;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  background-color: rgba($background, .9);
  z-index: 100;
  transition: opacity .5s;
  padding: $layout_space;

  &:not(.active){
    display: none;
  }

  &_cover{
    @include button-reset;
    opacity: 0;
    @include abs-100;
  }

  &_inner{
    margin: auto 0;
    width: 100%;
  }

  &_content{
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
  }
}

//TABS
.tabs{
  
  &_inner{
    display: flex;
    position: relative;

    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color13;
    }
  }

  &_item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px 10px 19px 10px;
    font-size: 16px;
    line-height: (18/16);
    text-align: center;
    font-weight: 500;
    flex: 1 1 1%;

    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: $color6;
      border-radius: 4px;
      transform: scaleX(0);
      transition: transform .4s;
    }

    &.active,
    &:hover{
      &::after{
        transform: scaleX(1);
      }
    }
  }
}

//BOX MAP DROP
.box_mapdrop{
  
  &_button{
    display: block;
    position: relative;
    padding: 5px 40px 5px 0px;
    border-bottom: 1px solid $color13;
    z-index: 2;
    filter: none;
    margin: 0 20px;

    strong{
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: (16/14);
    }

    span{
      display: block;
      color: $color3;
      font-size: 10px;
      line-height: (12/10);
    }

    > .sico{
      position: absolute;
      top: calc(50% - 7px);
      width: 14px;
      height: 14px;
      right: 15px;
    }
  }

  .title_h4{
    position: relative;
    padding: 12px 20px 0 20px;
    z-index: 2;
  }

  .title_h4 + &_button{
    margin-top: 10px;
  }  

  &_top{
    position: relative;    
  }

  &_top.active{
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.2));
    z-index: 200;
  }

  &_inner{    
    display: none;

    &::before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;      
      border-radius: 16px 16px 0 0;
    }
  }

  &_top.active &_inner{
    display: block;
  }

  &_drop{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 16px 16px;
    padding: 10px 6px;
  }

  &_item{
    display: block;
    padding: 9px 14px;
    border-radius: 22px;
    transition: background-color .25s;

    &:hover{
      background-color: $color4;
    }

    strong{
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: (14/12);
    }

    span{
      display: block;
      color: $color3;
      font-size: 10px;
      line-height: (12/10);
    }
  }

  &_bottom{
    padding: 8px 20px 0 20px;
  }

  &_dl{
    @include button-reset;
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;

    &:hover{
      text-decoration: underline;
    }

    span{
      display: block;
    }

    .sico{
      width: 10px;
      height: 10px;
      margin-left: 5px;
    }
  }
}

//BOX USER DROP
.box_userdrop{
  position: relative;
  padding: 15px 10px;

  &.active{
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.2));
    z-index: 200;
  }

  .btn_user{
    width: 100%;
    z-index: 2;
  }  

  &_inner{    
    display: none;

    &::before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;      
      border-radius: 16px 16px 0 0;
    }
  }

  &.active &_inner{
    display: block;
  }

  &_drop{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 16px 16px;
    padding: 5px 6px 15px 6px;
  }

  &_item{
    display: flex;
    align-items: flex-start;
    padding: 8px 14px;
    border-radius: 22px;
    transition: background-color .25s;

    &:hover{
      background-color: $color4;
    }

    .sico{
      width: 12px;
      height: 12px;
    }

    strong{
      display: block;
      flex: 1 1 auto;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      padding-left: 5px;      
    }   
  }
}

.box_map{
  position: relative;
  &::before{
    @include pseudo-pad-percent(1790,782);
  }

  &_image{
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
  }

  .icon_dot{
    position: absolute;
    transform: translate(-50%,-50%);
    &:hover{
      z-index: $zindex_header;
    }
  }  
}