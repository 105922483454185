//HEADER
.header{
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;  
  height: 100%;
  background: linear-gradient(191.1deg, #5D1A2C 16.44%, #A81A40 82.93%);
  border-radius: 0px 30px 30px 0px;
  z-index: $zindex_header;
  width: $menu_width;
  will-change: transform;
  padding-bottom: $radius;
  overflow: hidden;
  transition: transform $header_timing;

  @include xl{
    transform: translateX(-130px);    
  }

  @include xlm{
    transform: translateX(-230px);
  }

  .show_header &{
    transform: translateX(0) 
  }

  &_inner{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &_top{
    padding: 0 0 30px 0;

    &_inner{
      display: flex;
      height: $header_height;      

      @include xl{
        transition: transform $header_timing;
        transform: translateX(65px);
      }

      @include xlm{
        height: $header_height_mobile;
      }
    }
  }

  .show_header &_top_inner{
    @include xl{
      transform: translateX(0) 
    }
  }

  &_button{
    @include button-reset;
    display: block;
    position: relative;
    width: 100%;
    color: #fff;
    transform: translateZ(0);

    .btn_back{
      @include xl{
        opacity: 0;
        transform: scale(.8);
        transition: transform .35s, opacity .35s;
      }
    }

    .icon{
      position: absolute;
      color: $color1;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      margin: -12px 0 0 -12px;

      @include xl{
        transition: transform .35s, opacity .35s;  
      }

      @include xlm{
        display: none;
      }
    }
  }

  .show_header &_button{
    @include xl{
      .btn_back{
        opacity: 1;
        transform: none;
      }

      .icon{
        opacity: 0;
        transform: scale(.8); 
      }
    }
  }

  &_logo{
    display: block;
    will-change: transform;   
    transform-origin: 100% 50%;
    transform: scale(.6) translateX(13.5%);
    transition: transform $header_timing;
    margin-top: 8px;
    color: #fff;

    .sico{
      display: block;
      width: 130px;
      height: 38px;
      margin: 0 auto;
    }
  }

  .show_header &_logo{
    transform: none;
  }

  &_bottom{
    width: 100%;
    display: flex;
    overflow-x: hidden;
    flex: 1 1 auto;
    min-height: min-content; 
  }
}

//HEADER OVERLAY
.header_overlay{
  @include button-reset;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($background, .7);
  z-index: $zindex_header;
  pointer-events: none;
  opacity: 0;
  transition: opacity $header_timing;
}

.show_header .header_overlay{
  opacity: 1;
  pointer-events: all;
}

.show_sidebar .header_overlay{
  @include xlm{
    opacity: 1;
    pointer-events: all;
  }
}

//TOPBAR
.topbar{
  position: fixed;
  left: $left_offset - 10px;
  right: $right_offset - $layout_space;
  padding: 0 $layout_space;
  top: 0;  
  height: $header_height;
  z-index: $zindex_header - 10;  

  @include xlm{
    height: $header_height_mobile;
    left: 0;
    right: 0;
  }

  &::before{
    content: '';
    @include abs-100;
    background-color: #fff;
    border-radius: 0 0 $radius $radius;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    opacity: 0;
    will-change: opacity;
    transition: opacity .25s;
  }

  .scrolled &::before{
    opacity: 1;
  }

  @include xl{
    transition: transform $header_timing;
  }

  .show_header &{
    @include xl{
      transform: translateX(#{($menu_width - 100px)/2});
    }
  }

  &_inner{
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;    
  }

  &_left{
    display: flex;
    height: 100%;
    align-items: center;
  }

  &_right{
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: auto;

    > * + *{
      margin-left: 15px;
      @include xlm{
        margin-left: 10px;
      }
    }
  }

  &_logo{
    display: block;
    width: 100px;
    margin-left: 10px;
    height: 100%;

    @include mdm{
      width: 60px;
    }

    img{
      width: 100%;
    }
  }
}

//SIDEBAR
.sidebar{
  position: fixed;  
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: #FFFFFF;
  border-radius: 30px 0px 0px 30px;
  z-index: $zindex_header - 5;
  transition: transform $header_timing;

  .show_header &{
    @include xl{
      transform: translateX(#{($menu_width - 100px)/2});
    }
  }

  @include xlm{
    transform: translateX(100%);
    z-index: $zindex_header;
  }

  .show_sidebar &{
    @include xlm{
      transform: translateX(0);
    }
  }

  &_top{
    height: $header_height_mobile;
  }

  &_bottom{
    width: 100%;
    text-align: center;
    margin-top: auto;
  }

  &_scroll{
    overflow-x: hidden;
    min-height: 120px;
    height: 21vh;
    max-height: 180px;
  }
}

//NAV
.nav{
  display: flex;
  flex-direction: column;
  min-height: min-content;
  padding-left: 12px;
  width: 100%;
  transition: transform .45s;
  transform: translateX(143px);

  .show_header &{
    transform: translateX(0);
  }

  a{
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: $radius 0 0 $radius;
    color: #fff;
    font-weight: 700;
    padding: 0 13px 0 13px;
    margin-bottom: 12px;
    transition: background-color .35s;
  }

  a.active,
  a:hover{
    background-color: rgba($color1, .4);
  }

  a:last-child{
    margin-top: auto;
    margin-bottom: 0;
  }

  .sico{
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    color: $color1;
    transition: transform $header_timing;
  }

  .show_header & .sico{
    transform: scale(.6666);
  }

  span{
    display: block;
    flex: 1 1 auto;
    padding-left: 15px;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity $header_timing, transform $header_timing;
  }

  .show_header & span{
    transform: translateX(0);
    opacity: 1;
  }
}