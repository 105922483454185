// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$string: '' + $string;
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// =============================================================================
// Media queries
// =============================================================================

@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}
 
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}
 
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}
 
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin xxl{
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
}


//MAX WIDTH
@mixin minm {
	@media (max-width: #{$screen-min-max}) {
		@content;
	}
}

@mixin ssmm {
	@media (max-width: #{$screen-ssm-max}) {
		@content;
	}
}

@mixin smm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
 
@mixin mdm {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}
 
@mixin lgm {
	@media (max-width: #{$screen-lg-max}) {
		@content;
	}
}
 
@mixin xlm {
	@media (max-width: #{$screen-xl-max}) {
		@content;
	}
}

@mixin xxlm {
	@media (max-width: #{$screen-xxl-max}) {
		@content;
	}
}

@mixin xxxlm {
	@media (max-width: #{$screen-xxxl-max}) {
		@content;
	}
}

@mixin fullm {
	@media (max-width: #{$screen-full-max}) {
		@content;
	}
}

@keyframes sk-pulse {
  0% {
    transform: scale(0); 
  } 100% {
    transform: scale(1);
    opacity: 0; 
  }
}

@mixin loader{
  width: 31px;
  height: 31px;  
  background-color: $c_orange;    
	border-radius: 50%;
  animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955); 
}

@mixin placeholders($color, $opacity, $fw: 400, $transition: false, $class: ''){   
  &#{$class}::-webkit-input-placeholder{color: rgba($color, $opacity); opacity: 1; font-weight: $fw; @if $opacity == 0{font-size: 0;}; @if $transition{transition: #{$transition} .25s} }
  &#{$class}::-moz-placeholder{color: rgba($color, $opacity); opacity: 1; font-weight: $fw; @if $opacity == 0{font-size: 0;}; @if $transition{transition: #{$transition} .25s}  }
  &#{$class}:-ms-input-placeholder{color: rgba($color, $opacity); opacity: 1; font-weight: $fw; @if $opacity == 0{font-size: 0;}; @if $transition{transition: #{$transition} .25s}  }
  &#{$class}:-moz-placeholder{color: rgba($color, $opacity); opacity: 1; font-weight: $fw; @if $opacity == 0{font-size: 0;}; @if $transition{transition: #{$transition} .25s}  }
}

@mixin hide-input(){
  position: absolute;
  left: 0;
  top: 0;  
  width: 1px;
  height: 1px;
  border: 0;
  outline: none;
  opacity: 0;
}

@mixin createList($s1, $property, $values, $suffix: '', $param: '') {
	@each $value in $values {
		@at-root #{'.' + $s1 + (index($values, $value)) + $suffix + $param}{
			#{$property}: #{$value};
		}
	} 	
} 

@mixin createLocalVar($s1, $property, $values) {
	@each $value in $values {
		@at-root #{'.' + $s1 + (index($values, $value))}{
			#{'--' + $property}: #{$value};
		}
	} 	
} 

@mixin createLocalVarRGB($s1, $property, $values, $single: '') {
	@if $single != '' {
		@at-root #{'.' + $s1}{
			#{'--' + $property}: #{hex-to-rgb($values)};
		}
	} @else {
		@each $value in $values {
			@at-root #{'.' + $s1 + (index($values, $value))}{
				#{'--' + $property}: #{hex-to-rgb($value)};
			}
		} 
	}	
} 

@mixin createPair($s1, $property, $values, $replace, $resolutions: '', $ignore: '') {  
	$values: $values;
	
	@if $ignore == '' {
		@if $replace == '' {
			$replace: $values;
		}
	}

	@each $value in $values {
		@at-root #{'.' + $s1 + nth($replace, index($values, $value))}{
			#{$property}: #{$value};
		}
	} 

	@if $resolutions != '' {
		@each $res in $resolutions {

			//LGM
			@if $res == 'xlm' {
				@include xlm{
					@each $value in $values {
						@at-root #{'.' + $s1 + nth($replace, index($values, $value)) + '_' + $res}{
							#{$property}: #{$value};
						}
					} 	
				}
			}			

			//LGM
			@if $res == 'lgm' {
				@include lgm{
					@each $value in $values {
						@at-root #{'.' + $s1 + nth($replace, index($values, $value)) + '_' + $res}{
							#{$property}: #{$value};
						}
					} 	
				}
			}

			//LG
			@if $res == 'lg' {
				@include lg{
					@each $value in $values {
						@at-root #{'.' + $s1 + nth($replace, index($values, $value)) + '_' + $res}{
							#{$property}: #{$value};
						}
					} 	
				}
			}

			//MD
			@if $res == 'md' {
				@include md{
					@each $value in $values {
						@at-root #{'.' + $s1 + nth($replace, index($values, $value)) + '_' + $res}{
							#{$property}: #{$value};
						}
					} 	
				}
			}

			//MDM
			@if $res == 'mdm' {
				@include mdm{
					@each $value in $values {
						@at-root #{'.' + $s1 + nth($replace, index($values, $value)) + '_' + $res}{
							#{$property}: #{$value};
						}
					} 	
				}
			}
		} 		
	}	
}  

@mixin title_h1{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
  font-weight: 700;
  line-height: 1.1;
	font-size: 52px;
	font-size: clamp(29px, 4.5vw, 52px);
	min-height: .000001vw;
}

@mixin title_h2{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
  font-weight: 700;
  line-height: (41/36);
	font-size: 36px;
	font-size: clamp(24px, 4.5vw, 36px);
	min-height: .000001vw;
}

@mixin title_h22{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
  font-weight: 700;
  line-height: (41/36);
	font-size: 46px;
	font-size: clamp(24px, 4.5vw, 46px);
	min-height: .000001vw;
}

@mixin title_h3{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
  font-weight: 700;
  line-height: (30/26);
	font-size: 26px;
	font-size: clamp(22px, 4.5vw, 26px);
	min-height: .000001vw;
}

@mixin title_h4{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
  font-weight: 700;
  line-height: $lineheight18;
	font-size: 18px;
}




