//DISPLAY
.display{
  @include createPair('d' , 'display', ('flex', 'block', 'inline-block'), ('flex', 'block', 'iblock'), ('lgm', 'lg'));
}

.color{
  color: $color;

  @include createList('color' , 'color', $colors);

  &_red{
    color: $c_red;
  }

  &_green{
    color: $c_green;
  }

  &_white{
    color: #fff;
  }

  &_white_hover:hover{
    color: #fff;
  }
}

.bg{
  &_white{
    background-color: #fff;
  }
}

.flex{  
  &-wrap{
    flex-wrap: wrap;
  }
}

/**clear**/
.cf:after {
  content:"";
  display:table;
  clear:both;
}

.clear {
  clear:both;
  font-size:1px;
  height:0.01px;
  line-height:1px;
  margin:0;
  padding:0;
}

/**HELP*/
.text{
  @include createPair('text_' , 'text-align', ('center', 'left', 'right'), '', ('lgm', 'lg', 'mdm', 'md'));

  &_uc{
    text-transform: uppercase;
  }

  &_ul{
    text-decoration: underline;
  }

  &_italic{
    font-style: italic;
  }

  &_nowrap{
    white-space: nowrap;
  }

  &_ul_hover:hover{
    text-decoration: underline;  
  }

  @include createList('text_w' , 'font-weight', (100,200,300,400,500,600,700,800,900), '00');

  &_s12{
    font-size: 12px;
    line-height: $lineheight12;
  }

  &_s16{
    font-size: 16px;
    line-height: $lineheight16;
  }

  &_s20{
    font-size: 20px;
    line-height: $lineheight20;
  }
}

.wmax{
  $v1: ('350px', '370px', '460px', '500px','100%');
  $v2: (350, 370, 460, 500, '100p');
  @include createPair('wmax' , 'max-width', $v1 , $v2, ('lgm', 'lg', 'mdm'));  
}

.wmin{
  $v1: ('100px','150px', '190px','100%');
  $v2: (100, 150, 190, '100p');
  @include createPair('wmin' , 'min-width', $v1 , $v2, ('xlm','lgm', 'lg', 'mdm'));  
}

.w{
  $v1: ('100%', 'auto');
  $v2: ('100p', 'auto');
  @include createPair('w' , 'width', $v1 , $v2, ('lgm', 'lg', 'mdm'));  
}

.wh{
  &8{
    width: 8px;
    height: 8px;
  }

  &10{
    width: 10px;
    height: 10px;
  }

  &12{
    width: 12px;
    height: 12px;
  }

  &12-8{
    padding: 2px;
    width: 12px;
    height: 12px;
  }

  &16{
    width: 16px;
    height: 16px;
  }

  &24{
    width: 24px;
    height: 24px;
  }
}

.v{
  &_top{
    vertical-align: top;
  }

  &_mid{
    vertical-align: middle;
  }

  &_base{
    vertical-align: baseline;
  }
}

/***FLOATS***/
.fl{
  float: left;
}

.fr{
  float: right;
}

/*RELATIVE*/
.pos_rel{
  position: relative;
}

.pos_abs{
  position: absolute;
}

.block_right{
  margin-left: auto;
  margin-right: 0;
}

.hidden{
  display: none !important;
}

.img_block{
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.block_center{
  margin-left: auto;
  margin-right: auto;
  &-lgm{
    @include lgm{
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.hide{  
  @include createPair('hide' , 'display', ('none !important'), (''), ('xl', 'xlm', 'lgm', 'lg', 'md', 'mdm'), 'ignore');
}

.show{
  &_xlm{
    @include xl {
      display: none !important;
    }
  }

  &_lgm{
    @include lg {
      display: none !important;
    }
  }

  &_mdm{
    @include md {
      display: none !important;
    }
  }
}

hr{
  box-sizing: content-box;
  border: 0;
  border-top: 1px solid $background;
  margin: 0;
}