
.fade-enter {
    opacity: 0;
}
  
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
  
.fade-exit {
    opacity: 1;
}
  
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


.fade-fast-enter {
  opacity: 0;
}

.fade-fast-enter-active {
  opacity: 1;
  transition: opacity 150ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-fast-exit {
  opacity: 1;
}

.fade-fast-exit-active {
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

img {
  transition: opacity 1s ease !important;
  transition-delay: 10ms;
}

.img-not-loaded {
  opacity: 0 !important;
}

.img-loaded {
  opacity: 1 !important;
}

.loader {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(255,255,255,.96);
  z-index: 100;
  
  img {
    width: 60px;
    height: auto;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.btn_loading {
  overflow: hidden;
  pointer-events: none;
  
  &:before {
    pointer-events: all;
    z-index: 100;
    cursor: wait;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/layout/loader.svg');
    background-size: 40px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffffcc;
  }
}