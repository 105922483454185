
@font-face {
  font-family: 'ubuntu';
  src: url('../../../fonts/ubuntu-bold-webfont.woff2') format('woff2'),
       url('../../../fonts/ubuntu-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ubuntu';
  src: url('../../../fonts/ubuntu-light-webfont.woff2') format('woff2'),
       url('../../../fonts/ubuntu-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ubuntu';
  src: url('../../../fonts/ubuntu-medium-webfont.woff2') format('woff2'),
       url('../../../fonts/ubuntu-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ubuntu';
  src: url('../../../fonts/ubuntu-regular-webfont.woff2') format('woff2'),
       url('../../../fonts/ubuntu-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ubuntu';
  src: url('../../../fonts/ubuntu-italic-webfont.woff2') format('woff2'),
       url('../../../fonts/ubuntu-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}