$layout_width_full: 1440px;
$layout_width0: 1220px;
$layout_width: 1300px;

$layout_width_lgm: 570px;
$layout_width_mdm: 400px;
$layout_space: 20px;


$header_timing: .45s;
$left_offset: 130px;
$left_offset_mobile: 20px;
$right_offset: 345px;
$right_offset_mobile: 20px;
$menu_width: 230px;

//Breakpoints
$screen-min-min: 320px;
$screen-ssm-min: 421px;
$screen-sm-min: 578px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1351px;
$screen-xxl-min: 1601px;
$screen-xxxl-min: 1701px;

//Breakpoints
$screen-min-max: 319px;
$screen-ssm-max: 420px;
$screen-sm-max: 577px;
$screen-md-max: 767px;
$screen-lg-max: 1023px;
$screen-xl-max: 1350px;
$screen-xxl-max: 1600px;
$screen-xxxl-max: 1700px;
$screen-full-max: $layout_width_full;

//VARIABLES
$mq_min: 319px;

$font1: 'ubuntu', sans-serif;

$fontsize: 14px;
$lineheight: 1.42857143;
$fontweight: 400;
$color: #0F3F62;

$color1: #EDC2CE;

$background: #F1F9FC;
$font: $font1;

$color2: #79869F;
$color3: #8CACC2;
$color4: #ECF7FF;
$color5: #C8D7F4;
$color6: #A81A40;
$color7: $background;
$color8: #FAE4ED;
$color9: #E8F4F9;
$color10: #F8BA48;
$color11: #28475D;
$color12: #C4302F;
$color13: #EEDFE4;
$color14: #EFF8FB;
$color15: #EEF7FA;
$color16: #F2D3DC;

$colors: $color1, $color2, $color3, $color4;

$c_red: #C4302F;
$c_green: #18B928;

$c_userbg: #FFF4F7;


$radius: 30px;

//SOCIAL
$c_fb: #3b5998;
$c_tw: #00acee;
$c_insta: #8a3ab9;

$c_yt: #ff0000;
$c_in: #0077b5;
$c_mess: #006AFF;
$c_wa: #25D366;
$c_error: #C84F4F;

$lineheight10: 1.31;
$lineheight12: 14px;
$lineheight14: 1.42857143;
$lineheight16: 22px;
$lineheight16_em: 1.375;
$lineheight18: 1.3333333;
$lineheight20: 1.25;

$grid_space: 20px;
$grid_space_small: 10px;
$grid_space_mid: 30px;
$grid_space_big: 60px;

$header_height: 88px;
$header_offset: 96px;
$header_height_mobile: 70px;
$header_offset_mobile: 78px;

$zindex_header: 300;
$transition: .25s;

$header_top_color: $color1;
$header_top_height: 38px;

