.main{

  &_top{
    color: #fff;
    background: linear-gradient(191.1deg, #5D1A2C 16.44%, #A81A40 82.93%);
    border-radius: 0 0 $radius $radius;
  }

  &_head{
    display: flex;
    min-height: $header_height;  
    align-items: center;    
    padding: 20px 40px;
    
    @include mdm{
      min-height: 1px;  
      flex-wrap: wrap;
    }

    &_left{
      flex: 1 1 1px;     

      @include mdm{
        text-align: center;
        flex: 0 1 auto;
        width: 100%;
      }
    }

    &:After{
      @include md{
        content: '';
        display: block;
        flex: 1 1 1px;
      }
    }
  }

  &_logo{
    display: block;
    margin: 0 auto;
    flex: 0 0 130px;
    width: 130px;
    height: 40px;

    @include mdm{
      margin-top: 20px;
    }
  }
}