.message {
    &-wrapper {
        width: 100%;
        text-align: center;

        >div {
            text-align: center;
        }
    }

    &-content {
        max-width: calc( 100% - 20px );
        margin-top: 2rem;
        margin-left: 20px;
        background: $c_wa;
        display: inline-block;
        color: white;
        padding: .5rem 2rem;
        border-radius: 3rem;

        &--warning {
            background: $c_error;
        }
    }
}

.loader-wrapper {
    position: relative;
    overflow: hidden;
}

.dashboard-loader {
    $loaderSize: 30px;

    position: relative;

    .loader {
        right: 0;
        left: initial;
        width: $loaderSize;
        height: $loaderSize;

        .loading {
            img {
                width: $loaderSize;
                height: $loaderSize;
            }
        }
    }
}

.rdrCalendarWrapper {
    font-size: 12px;
    font-family: 'ubuntu', sans-serif;
    text-align: center;

    .rdrMonths {
        text-align: center;
    }

    .rdrMonth {
        margin: 0 auto;
        width: 20em;
        padding-bottom: 0;
    }

    .rdrMonthAndYearWrapper {
        padding-top: 0;
        height: 35px;
    }

    .rdrWeekDay,
    .rdrDay {
        line-height: 2em;
        height: 2.5em;
    }

    .rdrNextPrevButton {
        background: transparent;
    }

    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge,
    .rdrStartEdge {
        border-top-left-radius: .5em;
        border-bottom-left-radius: .5em;
    }

    .rdrDayEndOfWeek .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrEndEdge {
        border-top-right-radius: .5em;
        border-bottom-right-radius: .5em;
    }

    .rdrDayStartPreview {
        border-radius: .5em;
    }

    .rdrDayStartPreview,
    .rdrEndEdge,
    .rdrInRange,
    .rdrStartEdge,
    .rdrDayNumber {
        top: 2px;
        bottom: 2px;
    }
}

.calendar-date {
    font-size: clamp(24px, 4vw, 46px);
    margin: 0;
    font-weight: 500;
}

.react-datepicker {
    border: 0;
    font-family: 'ubuntu', sans-serif;

    &__current-month {
        opacity: 0;
    }

    &__header {
        background: transparent;
        border-bottom: none;
        font-size: 10px;
        text-transform: capitalize;
    }

    &__day {
        &--highlighted {
            background-color: $color4;
            color: $color;
            font-weight: bold;

            &:hover {
                background-color: $color6 !important;
                color: white !important;
            }
        }

        &--selected {
            background-color: $color6;
            color: white !important;

            &:hover {
                background-color: $c_red !important;
            }
        }
    }
}

.modal1 {
    justify-content: center;
    align-items: center;
}

.modal_inner {
    z-index: 100;
    width: auto;
}

.element_drop_content {
    max-height: 300px;
    overflow-y: auto;
}

.day-datepicker {
    font-family: 'ubuntu', sans-serif;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            width: calc( 50% - 20px );
            margin: 2px;
            text-align: center;
            transition: all .5s ease;
            
            span {
                padding: 3px 10px;
                display: inline-block;
                border-radius: 20px;
                cursor: pointer;

                &:hover {
                    background: $color4;
                }
            }

            &.active span {
                background: $color4;
            }
        }
    }

    @media screen and (min-width: 700px) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        min-width: 330px;

        .days {
            width: 100px;
        }

        ul {
            li {
                display: block;
                width: 100%;
                margin: 3px 0;
                text-align: left;
            }
        }
    }

    .calendar {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 2px solid $color14;

        @media screen and (min-width: 700px) {
            border-top: 0;
            border-left: 2px solid $color14;
            padding-top: 0;
            margin-top: 0;
            padding-left: 10px;
            margin-left: 10px;
        }

        @for $i from 0 through 12 {
            &.selected-month-#{$i} {
                .react-datepicker__month-#{$i} {
                    background: $color4;
                    font-weight: bold;

                    &:hover {
                        background: $color;
                        color: white;
                    }
                }
            }
        }

        &>div {
            text-align: center;
        }

        .react-datepicker {
            color: $color;
            margin: 0 auto;

            &__header {
                font-size: 15px;
                color: $color;
                font-weight: normal;
            }

            &__current-month {
                opacity: 1;
                color: $color;
                font-weight: normal;
            }

            &__day {
                color: $color;
                -webkit-tap-highlight-color: transparent;
                
                &--today {
                    font-weight: bold;
                    background: transparent;
                    color: $color;
                }

                &--keyboard-selected {
                    background: transparent;
                    color: $color;
                }


                &--highlighted {
                    background-color: $color4;
                    color: $color;
                    font-weight: bold;

                    &:hover {
                        background-color: $color !important;
                        color: white !important;
                    }

                    &.highlighted-1 {
                        background-color: rgba(153, 223, 255, .3);
                    }

                    &.highlighted-2 {
                        background-color: rgba(153, 223, 255, .7);
                    }

                    &.highlighted-3 {
                        background-color: rgba(153, 223, 255, 1);
                    }
                }
                &--disabled {
                    opacity: .2;
                }

                &-name {
                    font-size: 12px;
                }
            }
            

            &__month {

                &-text {
                    -webkit-tap-highlight-color: transparent;
                    width: 3.7rem;
                    border-radius: 5px;
                    padding: 3px;
                    transition: all .3s ease;

                    &:hover {
                        background-color: #f0f0f0;
                    }

                    &--keyboard-selected {
                        background: transparent;
                        color: $color;
                    }
                }
            }
        }
    }

    .years {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 210px;
        flex-wrap: wrap;

        .year {
            width: 60px;
            margin: 5px;
            padding: 5px 10px;
            text-align: center;
            transition: all .5s ease;
            border-radius: 20px;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;

            &__current {
                font-weight: bold;
            }

            &:hover {
                background-color: #f0f0f0;
            }

            &__active {
                background-color: $color4;

                &:hover {
                    background-color: $color;
                    color: white;
                }
            }
        }
    }
}

.btn_filter_list {
    &--wrapper {
        width: 100%;
        border-bottom: 2px solid $color14;
        margin: 0;
        padding: 5px 0;

        &:last-child {
            border-bottom: 0;
        }
        
        &>* {
            margin: 5px;
        }
    }
}

.statistics {
    .btn_action.text_nowrap {
        $iconSize: 27px;
        font-size: 20px;
        svg {
            width: $iconSize;
            height: $iconSize;
        }
    }
    .btn_action.text_nowrap_download {
        $iconSize: 20px;
        font-size: 16px;
        svg {
            width: $iconSize;
            height: $iconSize;
        }
    }
}

.tabs_item {
    &.disabled {
        opacity: .2;
        pointer-events: none;
    }
}

span.tabs_item {
  cursor: pointer;
}

.table {
    &.-gender {
        th:last-child {
            border-bottom-right-radius: 0 !important;
        }

        .thead {
            th:last-child {
                border-top-right-radius: 0 !important;
            }
        }
    }
}