/*GRID*/
@mixin grid_cols($space) {
  > .col1, > .col2, > .col25, > .col23, > .col3, > .col35, > .col34, > .col4,
  > .col5, > .col6, > .col_default,
  > .col22p,
  > .col30p,
  > .col35p,
  > .col40p,
  > .col45p,
  > .col55p,
  > .col56p,
  > .col60p,
  > .col70p,
  > .col_custom1,
  > .col_custom2{
    margin-left: $space;      
  }

  > .col1{
    width: calc(100% - #{$space});
  }

  > .col23{    
    width: calc(66.666% - #{$space});
  }

  > .col2{    
    width: calc(50% - #{$space});
  }   
  
  > .col25{    
    width: calc(40% - #{$space});
  }  

  > .col3{    
    width: calc((33.333%) - #{$space});
  }

  > .col30p{    
    width: calc(30% - #{$space});
  }

  > .col34{    
    width: calc(75% - #{$space});
  }

  > .col4{    
    width: calc(25% - #{$space});
  }

  > .col5{    
    width: calc(20% - #{$space});
  }

  > .col35,
  > .col60p{    
    width: calc(60% - #{$space});
  }

  > .col6{    
    width: calc(16.6666% - #{$space});
  }

  > .col22p{    
    width: calc(22% - #{$space});
  }

  > .col35p{    
    width: calc(35% - #{$space});
  }

  > .col_custom1{    
    width: calc(37.78% - #{$space});
  }

  > .col_custom2{    
    width: calc(24.44% - #{$space});
  }

  > .col40p{    
    width: calc(40% - #{$space});
  }

  > .col45p{    
    width: calc(45% - #{$space});
  }

  > .col55p{    
    width: calc(55% - #{$space});
  }

  > .col56p{    
    width: calc(56% - #{$space});
  }

  > .col70p{    
    width: calc(70% - #{$space});
  }

  > .offset{   
    margin-top: $space;
  }

  > .col_equal{    
    flex: 1 1 0%;
    margin-left: $space;  
  }

  > .col_fill{    
    flex: 1 1 auto;
    margin-left: $space;  
    min-width: 0;
  }
  
  > .offset_double{  
    margin-top: $space*2;
  }

  > .offset_half{  
    margin-top: $space/2;
  }

  > .col_end{
    margin-left: auto;
    padding-left: $space;
  }
 

  @media (max-width: $screen-xl-max) {  
    > .col5_xlm{    
      width: calc(20% - #{$space});
    } 

    > .col25_xlm{    
      width: calc(40% - #{$space});
    } 

    > .col30p_xlm{    
      width: calc(30% - #{$space});
    } 

    > .col2_xlm{    
      width: calc(50% - #{$space});
    }  

    > .col3_xlm{    
      width: calc(33.333% - #{$space});
    }  

    > .col4_xlm{    
      width: calc(25% - #{$space});
    }  

    > .col233_xlm{    
      width: calc((66.666%/3) - #{$space});
    }

    > .offset_double_xlm{  
      margin-top: $space*2;
    }
  }

  @media (max-width: $screen-lg-max) {  
    > .col1_lgm{    
      width: calc(100% - #{$space});
    }  

    > .col_end.col1_lgm{
      margin-left: $space;
      padding-left: 0;
    }

    > .col2_lgm{    
      width: calc(50% - #{$space});
    }  

    > .col3_lgm{    
      width: calc(33.333% - #{$space});
    }  

    > .offset_double_lgm{  
      margin-top: $space*2;
    }

    > .offset_half_lgm{  
      margin-top: $space/2;
    }
  }

  @media (max-width: $screen-md-max) {  
    > .col1_mdm{    
      width: calc(100% - #{$space});
    }  

    > .col2_mdm{    
      width: calc(50% - #{$space});
    }  

    > .col3_mdm{    
      width: calc(33.333% - #{$space});
    }  

    > .offset_double_mdm{  
      margin-top: $space*2;
    }

    > .offset_triple_mdm{  
      margin-top: $space*3;
    }
  }

  @media (max-width: $screen-sm-max) {
    > .col1_smm{    
      width: calc(100% - #{$space});
    }    
  }

}

.grid{
  $space: $grid_space;
  display: flex;
  margin-left: $space * -1;

  &:not([class*="flex-nowrap"]){
    flex-wrap: wrap;  
  }

  @include grid_cols($space);  

  &.mid-space{
    $space: $grid_space_mid;
    margin-left: $space * -1;
    @include grid_cols($space);
  } 

  &.small-space{
    $space: $grid_space_small;
    margin-left: $space * -1;
    @include grid_cols($space);
  } 

  &.big-space{
    $space: $grid_space_big;
    margin-left: $space * -1;
    @include grid_cols($space);
  } 
  
  &.zero-space{
    $space: 0px;
    margin-left: $space * -1;
    @include grid_cols($space);
  } 
}

.flex-nowrap{
  &-lg{
    @include lg{
      flex-wrap: nowrap;
    } 

    @include lgm{
      flex-wrap: wrap;
    } 
  } 

  &-md{
    @include md{
      flex-wrap: nowrap;
    } 

    @include mdm{
      flex-wrap: wrap;
    } 
  } 
}

.justify-center{
  justify-content: center;
}

.justify-around{
  justify-content: space-around;
}

.justify-between{
  justify-content: space-between;
}

.justify-right,
.justify-end{
  justify-content: flex-end;
}

.self-center{
  align-self: center;

  &-xl{
    @include xl{
      align-self: center;  
    }
  }
}

.self-end{
  align-self: flex-end;
}

.align-center{
  align-items: center;
}

.align-start{
  align-items: flex-start;
}

.align-end{
  align-items: flex-start;
}

.order-first{
  order: -1;

  &-lg{
    @include lg{
      order: -1;  
    }
  }

  &-lgm{
    @include lgm{
      order: -1;  
    }
  }

  &-mdm{
    @include mdm{
      order: -1;  
    }
  }
}

.order-last{
  order: 9999;

  &-lg{
    @include lg{
      order: 9999;
    }
  }

  &-lgm{
    @include lgm{
      order: 9999; 
    }
  }

  &-md{
    @include md{
      order: 9999;
    }
  }
}

.justify-center{  
  &-lgm{
    @include lgm{
      justify-content: center;
    }
  }

  &-mdm{
    @include mdm{
      justify-content: center;
    }
  }

  &-smm{
    @include smm{
      justify-content: center;
    }
  }
}

.flex-dir{
  &-column{
    flex-direction: column;
  }
}