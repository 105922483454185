.item_center{
  display: flex;
  position: relative; 
  user-select: none;
  cursor: pointer;
  min-height: 40px;
  padding-right: 5px;

  &_list & + &{
    margin-top: 10px
  }

  &.type2 &_inner{
    padding: 1px 15px; 
    border-radius: 25px;
    transition: background-color .25s;

    &:hover{
      background-color: $color4;  
    }
  }

  &.type2 input:checked ~ &_inner{
    background-color: $color4;
  }

  &_inner{
    display: flex;
    width: 100%;
  }

  input{
    @include hide-input;
  }
  
  &_image{
    position: relative;
    flex: 0 0 60px;

    &::before{
      @include pseudo-pad-percent(16,9);
    }

    img{
      @include abs-100;
      object-fit: scale-down;
    }
  }

  &.type2 &_image{
    width: 51px;
    flex: 0 0 51px;
    height: 51px;
  }

  &_desc{
    align-self: center;
    flex: 1 1 auto;
    padding: 0 20px 0 10px;

    strong{
      display: block;
      font-size: 12px;
      line-height: (16/12);
      font-weight: 500;
    }
  
    span{
      display: block;
      color: $color3;
      font-size: 10px;
      line-height: (12/10);
    }
  }

  &.type2 &_desc{
    padding: 5px 0 5px 15px;
  }

  .sico{
    flex: 0 0 16px;
    align-self: center;
    height: 16px;

    g:last-child{
      opacity: 0;
    }
  }

  input:checked ~ .sico{
    g:last-child{
      opacity: 1;
    }  
  }
}

.item_activity{
  display: flex;
  min-height: 40px;
  padding-right: 5px;

  &.type2{
    border-radius: 15px;
    background-color: $color4;
    padding: 12px 15px;  
  }

  &_list & + &{
    margin-top: 10px
  }

  &_line{ 
    width: 3px;
    border-radius: 3px;
    flex: 0 0 3px;
  }
  
  &_desc{
    align-self: center;
    flex: 1 1 auto;
    padding: 0 20px 0 15px;

    a,
    strong{
      display: block;
      font-size: 12px;
      line-height: (16/12);
      font-weight: 500;
    }

    a:hover{
      text-decoration: underline;
    }

    a:last-child,
    strong:last-child{
      margin-top: 3px;
    }
  
    span{
      display: block;
      color: $color3;
      font-size: 10px;
      line-height: (12/10);      
    }

    span:last-child{
      margin-top: 3px;
    }
  }

  &_desc:first-child{
    padding-left: 0;
  }

  &_control{   
    align-self: center;
    position: relative;
    flex: 0 0 16px;

    > button{
      @include button-reset;      
      display: block;
    }

    &.active{
      z-index: 100;
    }
  }

  &_drop{
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: calc(50% - 13px);  
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 12px;
    line-height: $lineheight10;
    box-shadow: 0 0 5px rgba(#000,.1);     
    background-color: $color6;   

    button{
      @include button-reset;
      color: #fff;

      &:hover{
        text-decoration: underline;
      }
    }

    button + button{
      margin-left: 10px;
    }
  }

  &_control:not(.active) &_drop{
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .action_drop{
    right: -1px;
    top: calc(50% - 14px);    
  }

  &_control:not(.active) .action_drop{
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
  }
  
}

.item_report{
  display: flex;
  border: 1px solid $color1;
  border-radius: 24px;
  padding: 1px 30px 11px 30px;
  transition: box-shadow .25s;

  @include mdm{
    display: block;
    text-align: center;
  }

  &:hover{
    box-shadow: 0 0 0 2px $color1;
  }

  &_title{
    display: block;
    font-weight: 500;
    margin-top: 10px;
  }

  @at-root #{a + & + _title}:hover{
    text-decoration: underline;
  }

  &_type{
    display: block;
    color: $color3;
    margin-top: 10px;

    @include md{
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  @at-root #{a + & + _type}:hover{
    text-decoration: underline;
  }

  &_dl{
    margin-left: auto;
    margin-top: 10px;
  }

  &_list & + &{
    margin-top: 10px;
  }
}

.item_weather{
  display: flex;
  align-items: center;

  &_image:empty{
    display: none;
  }

  &_desc{
    color: $color2;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-left: 15px;
    flex: 1 1 auto;
  }
}

.item_image{
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $color15;
  transition: background-color .35s;

  &_label{
    display: flex;
    position: relative;

    input{
      @include hide-input;
    }
  }

  &_label &{
    min-width: 100%;
  }

  &.active,
  &:hover{
    background-color: $color4;
  }

  &_label input:checked ~ &{
    background-color: $color4;
  }

  &::before{
    @include pseudo-pad-percent(1,1);
  }

  &.type2::before{
    @include pseudo-pad-percent(150,200);  
  }
  
  &_img{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &_desc{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding-bottom: 57%;
    border: 10px solid transparent;

    strong{
      display: block;
      font-size: 20px;
      width: 100%;
      line-height: $lineheight20;
    }

    span{
      display: block;
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: $lineheight12;
    }
  }

}

.item_tooltip{
  position: absolute;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
  border-radius: $radius;
  background-color: #fff;
  min-width: 180px;
  max-width: calc(100vw - 40px);

  &_image{
    position: relative;

    &::before{
      @include pseudo-pad-percent(170,120);
    }

    img{
      @include abs-100;
      @include img-cover;
    }
  }

  img{
    display: block;
    width: 100%;
    border-radius:  $radius $radius 0 0;
  }

  &_desc{
    position: relative;
    padding: 10px 15px 10px 25px;
    font-size: 12px;
    line-height: $lineheight12;

    i{
      position: absolute;
      margin-top: calc(.5em - 4px);
      left: 9px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #FFCE20;
    }

    strong{
      display: block;
    }

    span{
      display: block;
      padding-top: 5px;
    }
  }

}