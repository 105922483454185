//TITLEs
.title_h1{
  @include title_h1
}

.title_h2{
  @include title_h2
}

.title_h22{
  @include title_h22
}

.title_h3{
  @include title_h3
}

.title_h4{
  @include title_h4
}

//TEXT
.text_basic{
  margin: 0;
  font-size: 16px;
  line-height: (28/16)
}

.btn_center{
  @include button-reset;
  position: relative;
  display: inline-flex;
  color: $color;
  padding: 15px 30px;
  border-radius: 0 0 $radius $radius;
  align-items: center;
  background-color: $color8;
  transition: background-color .25s;

  &_image{
    display: block;
    flex: 0 0 51px;

    img{
      display: block;
      max-width: 100%;
    }
  }

  &_desc{
    display: block;
    flex: 1 1 auto;
    padding: 0 0 0 15px;
    text-align: left;

    strong{
      display: block;
      font-size: 16px;
      line-height: $lineheight16;
      font-weight: 500;
    }

    span{
      display: block;
      padding-top: 2px;
    }
  }

  .sico.drop{
    margin-left: 10px;
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
    transition: transform .25s
  }

  .element_drop.active > & .sico.drop{
    transform: rotate(180deg)
  }

  &:hover{
    background-color: $color1;
  }

  .element_drop.active > &{
    background-color: $color1;  
  }  
}

//BUTTON BASIC
.btn_basic{
  @include button-reset;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  color: $color;
  border-radius: 22px;
  padding: 13px 20px 14px 20px;
  background-color: $color8;
  transition: background-color .25s;

  .sico{
    width: 16px;
    height: 16px;

    &:first-child{
      margin-right: 10px;
    }
  }
  
  .more{
    width: 10px;
    height: 10px;
    margin-left: 7px;
    transition: transform .25s;  
  }

  &:hover{
    background-color: $color1;

    .more{
      transform: translateX(5px)
    }
  }

  .element_drop.active > &{
    background-color: $color1;  
  }

  .sico.plus,
  .sico.drop{
    margin-left: 10px;
    width: 12px;
    height: 12px;
    transition: transform .25s
  }

  .element_drop.active > & .sico.drop{
    transform: rotate(180deg)
  }

  &.to_half{
    span:nth-child(1){
      order: -1;
    }

    .sico:nth-child(2){
      order: -1;
    }

    &:after{
      content: '';
      display: block;
      height: 32px;
      width: 1px;
      background-color: $color16;
      order: -1;  
      margin: -10px 15px; 
    }
  }
}

//ACTION DROP
.action_drop{
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 10px 5px;

  button{
    @include button-reset;
    color: $color;
    display: flex;
    min-width: 100%;
    font-size: 12px;
    white-space: nowrap;
    line-height: 1;
    padding: 5px 10px;
    border-radius: 12px;
    transition: background-color .25s; 

    &:hover{
      background-color: $color4;
    }

    .sico{
      margin-right: 5px;
    }
  }
}

//INPUT SELECT
.input_select{
  @include input-reset;
  position: relative;
  font-weight: 500;
  color: $color;
  border-radius: 22px;
  padding: 13px 36px 14px 20px;
  background-color: $color8;
  transition: background-color .25s;

  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand{
    display: none;
  }

  &.basic{
    background: none;
    border-radius: 0;
    padding: 5px 36px 5px 0;
  }

  &_cont{
    position: relative;    
  }

  & ~ .sico{
    position: absolute;
    right: 15px;
    top: 0;
    width: 10px;
    height: 100%;  
    pointer-events: none;
  }

  &.basic ~ .sico{  
    right: 5px;
  }

  &.big{
    font-weight: 500;
    font-size: 46px;  
    font-size: clamp(24px, 4vw, 46px); 
    padding: 0 .8em 0 0;

    option{
      font-size: 14px;
    }
  }

  &.mid{
    font-weight: 500;
    font-size: 26px;  
    font-size: clamp(18px, 2.5vw, 26px); 
    padding: 0 1em 0 0;

    option{
      font-size: 14px;
    }
  }

  &.basic.border{
    border-bottom: 1px solid $color13;
  }

  &.big ~ .sico{  
    right: 0;
    width: 20px;
    width: clamp(12px, 2vw, 20px); 
  }

  &:not(.basic):focus,
  &:not(.basic):hover{
    background-color: $color1;
  }
}

//USER
.btn_user{
  @include button-reset;
  position: relative;
  display: inline-flex;
  align-items: center;

  &_image{
    flex: 0 0 72px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: $c_userbg;
    padding: 11px; 

    > div{
      width: 100%;
      height: 100%;
      position: relative;         
      border-radius: 50%; 
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      padding: 1px;
      background-color: $color1;

      &::after{
        content: '';
        @include abs-100;
        border: 2px solid #fff;
        border-radius: 50%; 
      }

      div{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%; 
        overflow: hidden;  
      }

      img{
        @include img-cover;
      }
    }
  }

  &_desc{
    flex: 1 1 auto;
    padding: 0 30px 0 15px;
    font-weight: 500;

    strong{
      display: block;
      font-size: 16px;
      line-height: (18/16);
      font-weight: inherit;
    }

    span{
      display: block;
      color: $color1;
      line-height: (16/14);
      padding-top: 2px;
    }
  }

  &_arrow{
    position: absolute;
    right: 10px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    transition: transform .4s;
  }

  .box_userdrop.active &_arrow{
    transform: rotate(180deg)
  }
}

.btn_start{
  @include button-reset;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  color: $color;
  background-color: $color8;
  text-align: center;
  transition: background-color .25s;

  @include lgm{
    width: 80px;
    height: 80px;
  }

  .sico{
    height: 100%;
    width: 36px;
  }

  &:hover{
    background-color: $color1;
  }
}

//BTN BACK
.btn_back{
  @include button-reset;
  display: inline-flex;
  align-items: center;
  color: $color;

  .sico{
    flex: 0 0 9px;
    width: 9px;
    height: 11px;     
    transition: transform .25s;

    &:first-child{
      margin-right: 10px; 
    }

    &:last-child{
      margin-left: 10px; 
    }
  }

  &:hover .sico{
    &:first-child{
      transform: translateX(-5px);
    }

    &:last-child{
      transform: translateX(5px);
    }
  }
}

//BUTTON ICON
.btn_icon{
  @include button-reset;
  position: relative;
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
  color: $color;

  &.unread:after{
    content: '';
    position: absolute;
    top: 9px;
    right: 12px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $c_red;
  }
}

//NOFICATION BUTTON
.btn_noti{
  display: block;
  position: relative;
  border-radius: 15px;
  padding: 10px 25px 10px 13px;
  transition: background-color .25s;

  &:hover{
    background-color: $color4;
  }

  strong{
    display: block;
    font-size: 12px;
    line-height: (16/12);
    font-weight: 500;
  }

  span{
    display: block;
    padding-top: 5px;
    color: $color3;
    font-size: 10px;
    line-height: (12/10);
  }

  &.unread::after{
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $c_red;
  }

  &_list &{

    &:not(:last-child)::before{
      content: '';
      position: absolute;
      left: 13px;
      right: 15px;
      height: 1px;
      bottom: 0;
      background-color: $color4;
    }
  }

  &_list & + &{
    margin-top: -1px;
  }
  
}

//BTN ACTION
.btn_action{
  @include button-reset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: $lineheight14;

  @at-root a#{&}:hover{
    text-decoration: underline
  }

  .sico:first-child{
    margin-right: 10px
  }

  .sico:last-child{
    margin-left: 10px
  }

  &.small{
    font-size: 10px;
    line-height: 12px;

    .sico:first-child{
      margin-right: 5px
    }
  
    .sico:last-child{
      margin-left: 5px
    }
  }
}

//BUTTON FILTER
.btn_filter{
  @include button-reset;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  background-color: $color4;
  padding: 11px 15px;
  border-radius: 17px;
  transition: color .25s, background-color .25s;

  &:hover{
    background-color: $color;
    color: #fff;
  }

  &_list{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &_list > *{
    margin: 10px 10px 0 0;
  }

  .sico{
    width: 6px;
    height: 6px;
    margin-left: 10px;
  }
}

//BTN INVERVAL
.btn_interval{
  @include button-reset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 6px 19px;
  border-radius: 17px;
  line-height: $lineheight12;
  transition: background-color .25s;

  &_label{
    display: block;
    position: relative;
    cursor: pointer;
    input{
      @include input-hide;
    }
  }

  .sico{
    width: 18px;
    height: 22px;
    color: $color2;
  }

  input:checked ~ &,
  &.active,
  &:hover{
    background-color: $color4;
  }

  &_list &_label + &_label,
  &_list & + &{
    margin-top: 5px;
  }
}

//ELEMENT DROPDOWN
.element_drop{
  position: relative;

  &.active{
    z-index: $zindex_header + 10;
  }

  &_button{
    z-index: 2;
  }

  &.active &_button.btn_icon{
    background: none;
  }

  &_arrow{
    position: absolute;
    background-color: #fff;
    border-radius: $radius $radius 0 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;

    &::before{
      content: '';
      position: absolute;   
      width: 12px;
      height: 12px;
      bottom: 0;
      right: 100%;
      background-color: inherit;
      clip-path: url(#clip_tl);
    } 

    &::after{
      content: '';
      position: absolute;   
      width: 12px;
      height: 12px;
      bottom: 0;
      left: 100%;
      background-color: inherit;
      clip-path: url(#clip_tr);
    } 
  }

  &_inner{    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    filter: drop-shadow(0 4px 40px rgba(0, 0, 0, 0.1));
  }

  &:not(.active) &_inner{
    display: none;
  }

  &_content{
    position: absolute;    
  }

  //LANGS
  &.lang &_content{
    background-color: #fff;
    width: 100px;
    border-radius: 12px;
    padding: 14px;
    transform: translateX(-50%);
    background-color: #fff;
    top: 60px;
    left: 50%;

    a{
      display: block;
      font-style: 16px;
      line-height: $lineheight16_em;
      font-weight: 500;
      transition: background-color .25s;
      padding: 4px 10px;
      text-align: center;
      border-radius: 14px;

      &.active,
      &:hover{
        background-color: $color4;
      }
    }

    a + a{
      margin-top: 5px;
    }
  }

  //NOTIFICATIONS
  &.notification &_content{
    background-color: #fff;
    width: 220px;
    border-radius: 22px;
    padding: 12px;
    transform: translateX(-50%);
    background-color: #fff;
    top: 60px;
    left: 50%;
    overflow-x: hidden;
    max-height: calc(100vh - 200px);
  }

  &.notification &_arrow{
    &::after,
    &::before{
      width: 22px;
      height: 22px;
    }
  }

  &.basic &_inner{
    top: 50%;  
  } 

  &.basic &_content{
    max-width: calc(100vw - #{$layout_space*2});
    min-width: calc(100% + 30px);
    top: 36px;      
    background-color: #fff;
    border-radius: 20px;
  }    

  &.basic &_arrow{ 
    height: 36px;
    border-radius: 0;

    &::after,
    &::before{
      width: 20px;
      height: 20px;
    } 
  }

  &.small &_arrow{
    &::after,
    &::before{
      width: 16px;
      height: 16px;
    } 
  }

  &.small &_content{
    border-radius: 16px;
  }

  &.to_center &_content{
    min-width: calc(100% + 70px);
    left: 50%;
    transform: translateX(-50%);
  }
 
  &.to_left &_content{
    left: 0;
    min-width: calc(100% + 70px);
    border-top-left-radius: 0;    
  }

  &.to_left &_arrow{
    &::before{
      display: none
    }
  }

  &.to_right &_content{    
    right: 0;
    min-width: calc(100% + 70px);
    border-top-right-radius: 0;   
  }

  &.to_right &_arrow{
    &::after{
      display: none
    }
  }

  &.to_right2 &_content{  
    @include lg{
      left:auto;
      right: 0;
      border-top-right-radius: 0;    
    }  

    @include lgm{
      right: auto;
      left: 0;
      border-top-left-radius: 0;    
    }
  }

  &.to_right2 &_arrow{
    @include lg{
      &::after{
        display: none
      }
    }

    @include lgm{
      &::before{
        display: none
      }
    }
  }

  &.to_center2 &_content{  
    min-width: calc(100% + 70px);
    left: 50%;
    transform: translateX(-50%);

    @include lgm{
      right: auto;
      left: 0;
      transform: none;
      border-top-left-radius: 0;    
    }
  }

  &.to_center2 &_arrow{ 
    @include lgm{
      &::before{
        display: none
      }
    }
  }

  &_list{
    display: flex;
    flex-direction: column;   
      max-width: calc(100vw - #{$layout_space*2} - 120px); 
      min-width: min-content;    

    a{
      font-weight: 500;
      border-radius: 16px;
      display: block;
      min-width: 100%;
      //max-width: fit-content;
      width: max-content;   
      max-width: 100%;   
      padding: 7px 15px;
      transition: background-color .25s;
    }

    a.active,
    a:hover{
      background-color: $color4;
    }

    a + a{
      margin-top: 2px;
    }
  } 

  &.edit_note &_content{
    width: 440px;
    max-width: calc(100vw - #{$layout_space*2} - 40px);
  }

  &.basic &_list{
    max-width: calc(100vw - #{$layout_space*2} - 40px);     
  }

  &.dashboard  &_inner{
    top: 50%;
    height: 50%;
    &::before{
      content: '';
      @include abs-100;
      background-color: #fff;
    }
  }

  &.dashboard  &_content{
    width: 100%;
    top: 100%;
    min-width: 1px;
    max-width: 100%;
    border-radius: 0 0 20px 20px;
  }
  
}


//INPUT TEXT
.input_text{
  @include input-reset;
  background-color: $background;
  font-size: 14px;
  line-height: $lineheight14;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 22px;
  color: $color;
  transition: box-shadow .25s;

  @include placeholders($color, .2, 500);

  &:focus{
    box-shadow: 0 0 0 2px $color;
  }
}

//INPUT TEXT 2
.input_text2{
  @include input-reset;
  font-size: 16px;
  line-height: $lineheight16;
  font-weight: 500;
  padding: 5px 0;
  color: $color;
  border-bottom: 1px solid $color14;
  transition: border .25s;

  @include placeholders($color, .8, 500);

  &:focus{
    border-bottom: 1px solid $color;
  }
}

//LABEL BASIC
.label_basic{
  font-size: 12px;
  line-height: $lineheight10;
  display: block;
  font-weight: 500;
  padding: 0 20px 7px 20px;  
}

//LABEL RADIO
.label_radio{
  display: block;
  position: relative;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: initial;
  cursor: pointer;

  input{
    @include hide-input;
  }

  span{
    display: block;
    padding: 3px 10px;
    border-radius: 10px;
    transition: background-color .25s;
  }

  input:checked ~ span{
    background-color: $color4;
  }
}

.label_check{
  display: block;
  position: relative;
  font-size: 12px;
  line-height: $lineheight12;
  cursor: pointer;

  &_list + &_list{
    margin-top: 20px;
  }

  &_list & + &{
    margin-top: 6px
  }

  &_head + &{
    margin-top: 10px
  }

  input{
    @include hide-input;
  }

  span{
    display: flex;
    align-items: center;
    padding: 3px 10px;
    border-radius: 12px;
    transition: background .25s;
  }

  input:checked ~ span{
    background-color: $color4;
  }  
  
  strong{
    display: block;
    font-weight: 400;
    flex: 1 1 auto;
    padding-left: 9px;
  }

  i{
    display: block;
    border-radius: 50%;
    width: 7px;
    height: 7px;
  }

  &_head{
    display: flex;
    align-items: center;
    justify-content: space-between;  
    padding-bottom: 10px;
    border-bottom: 1px solid $background;

    strong{
      display: block;
      font-size: 12px;
      line-height: $lineheight12
    }
  }

  &_toggle{
    @include button-reset;
    font-size: 10px;
    line-height: $lineheight12;
    color: $color;

    &:not(:hover){
      text-decoration: underline;
    }
  }
}

.icon_dot{
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FFCE20;

  &::before{
    content: '';
    @include abs-100;
    transform: scale(2.5625);
    background-color: inherit;
    border-radius: 50%;
    opacity: .2;
    transition: all .25s;
  }

  &::after{
    content: '';
    @include abs-100;
    transform: scale(1.875);
    background-color: inherit;
    border-radius: 50%;
    opacity: .4;
    transition: all .25s;
  }

  &.pulse{
    &::before{
      animation: pulse 1.5s ease-in-out infinite forwards;
    }

    &::after{
      animation: pulse2 1.5s ease-in-out infinite forwards;
    }

    &:hover{
      &::before, &::after{
        animation: none;
      }
    }
  }

  @keyframes pulse{
    0%{
      transform: scale(1);
      opacity: .4;
    }
    100%{
      transform: scale(3);
      opacity: 0;
    }
  }

  @keyframes pulse2{
    0%{
      transform: scale(1);
      opacity: .6;
    }
    100%{
      transform: scale(2.2);
      opacity: 0;
    }
  }

  .item_tooltip{
    z-index: $zindex_header;
    transition: opacity .3s;

    &.to_left{
      right: 0;
    }
  }

  &:not(:hover) .item_tooltip{
    opacity: 0;
    pointer-events: none;
  }

  &.small{
    width: 8px;
    height: 8px;
    &::after{
      transform: scale(1.625);  
    }

    &::before{
      transform: scale(2.125);  
    }
  }
}

.dot{
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  background-color: #FFCE20;
  border-radius: 50%;
}

.table{
  width: 100%;

  &_scroll{
    width: 100%;
    overflow-y: hidden;
  }

  thead{

  }

  th{
    font-weight: 500;
    text-align: center;  
    background-color: $color4;
    padding: 12px 15px;
    
    &:first-child{
      position: sticky;
      left: 0;
      background-color: $color8;
      border-radius: 22px;         
      white-space: nowrap;
      box-shadow: 22px 0 0 0 $color4, -22px 0 0 0 #fff; 
    }

    &:last-child{
      border-radius: 0 22px 22px 0;    
    }    
  }

  tbody{
    font-size: 12px;
    line-height: $lineheight12;    
    text-align: center;

    td{      
      border-bottom: 1px solid $color4;
      &:not(:first-child){
        border-left: 1px solid $color4;
      }

      &:first-child{
        white-space: nowrap;
        padding: 15px 15px;
        color: $color2;
      }
    }
  }

  &_drop{
    min-width: 104px;
    position: relative;
    padding: 15px 20px;
    transition: all .25s;
    
    &:hover{
      background: #FAE4ED;
      box-shadow: 0 0 0 1px #F2D3DC;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));
      z-index: 100;
    }
    
    .drop{
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      width: 100%;
      font-size: 10px;
      padding: 10px;
      text-align: left;
      background-color: #fff;
      transition: opacity .2s;      

      div + div{
        margin-top: 5px;
      }
    }

    &:not(:hover){
      .drop{
        opacity: 0;
        pointer-events: none;
      }
    }

    &.narrow{
      min-width: 75px;
    }
  }

  tr:last-child &_drop{
    .drop{
      top: auto;
      bottom: calc(100% + 1px);
    }
  }
}

.table1{
  width: 100%;

  thead{
    
  }

  th{
    font-weight: 500;
    text-align: left;  
    background-color: $color4;
    padding: 12px 25px;
    
    &:first-child{
      background-color: $color8;
      border-radius: 22px;         
      @include md{
        box-shadow: 22px 0 0 0 $color4; 
      }
    }

    &:last-child{
      border-radius: 0 22px 22px 0;    
    }    
  }

  tbody{
    font-size: 10px;
    line-height: $lineheight10;    

    td{
      padding: 15px 25px;
      border-bottom: 1px solid $color4;
      &:not(:first-child){
        @include md{
          border-left: 1px solid $color4;
        }
      }

      &:last-child{
        color: $color3;
      }
    }
  }

  &,
  tbody,thead,tr,th, td{
    @include mdm{
      display: block;
      width: 100%;
    }
  }

  thead{
    th{
      &:not(:first-child){
        @include mdm{
          display: none;
        }  
      }
    }
  }

  tbody{
    td{
      @include mdm{
        border: 0;
        text-align: center;
      }  

      &:empty{
        @include mdm{
          display: none;
        }    
      }
    }

    tr{
      @include mdm{
        border-bottom: 1px solid $color4;
      }     
    }
  }
}